<template>
  <b-row>
    <b-col v-if="memberStartups.length > 0">
      <b-card
        no-body
      >
        <b-table
          :fields="fields"
          :items="memberStartups"
        >
          <template #cell(title)="data">
            <b-link :to="'/girisim-basvurularim/goruntule/' + data.item.id_startups">
              <b-row>
                <b-col cols="auto">
                  <b-avatar
                    v-if="!data.item.image"
                    :text="data.item.avatar_text"
                    variant="primary"
                    size="48"
                  />
                  <b-avatar
                    v-else
                    :src="data.item.image"
                    variant="primary"
                    size="48"
                  />
                </b-col>
                <b-col>
                  <div>
                    <div class="font-weight-bold text-primary font-medium-1 text-body">
                      {{ data.item.title }}
                    </div>
                    <small>{{ data.item.company }}</small>
                  </div>
                </b-col>
              </b-row>
            </b-link>
          </template>
          <template #cell(control)="data">
            <b-button
              variant="primary"
              size="sm"
              :to="'/girisim-basvurularim/goruntule/' + data.item.id_startups"
            >
              <FeatherIcon icon="EyeIcon" />
              Görüntüle
            </b-button>
          </template>
        </b-table>
      </b-card>
    </b-col>
    <b-col
      v-else
      cols="12"
    >
      <b-alert
        variant="warning"
        show
        class="p-1"
      >
        <div class="alert-body text-center">
          <span class="d-block">{{ $t('Henüz başvuru yapılmış girişiminiz yok.') }}</span>
        </div>
      </b-alert>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BAvatar, BAlert, BButton, BLink,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BAvatar,
    BAlert,
    BButton,
    BLink,
  },
  data() {
    return {
      fields: [
        {
          key: 'title',
          label: 'GİRİŞİM',
        },
        {
          key: 'startup_status',
          label: 'Durum',
          thStyle: { width: '200px' },
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100 text-nowrap',
          tdClass: 'text-nowrap',
        },
      ],
    }
  },
  computed: {
    memberStartups() {
      return this.$store.getters['memberStartups/getMemberStartups']
    },
  },
  created() {
    this.getMemberStartups()
  },
  methods: {
    getMemberStartups() {
      this.$store.dispatch('memberStartups/memberStartupsList')
    },
  },
}
</script>
<style scoped></style>
